import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css({
        background: variables.dpc_backgroundWhite,

        '.layout-container': {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-start',

            '.survey-step-question': {
                width: '65%',
                padding: '2em',
                
                color: variables.dpc_fontDark,
                minHeight: '85vh',
               
                '.survey-step-buttons': {
                    marginTop: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',

                    '.component-button': {
                        marginRight: '.5em',
                    },
                },
            },
            '.survey-steps-results': {
                width: '35%',
                padding: '2em',
                minHeight: '85vh',
                background: '#EEEEEE',

                '.survey-step-result': {
                    paddingBottom: '.5em',
                    marginBottom: '.5em',
                    borderBottom: '1px solid #EEEEEE',

                    '.survey-step-title': {
                        fontSize: '1.2em',
                        color: '#333333',
                    },
                    '.survey-step-subtitle': {
                        fontSize: '1.1em',
                        color: '#444444',
                    },
                    '.survey-step-value': {
                        fontSize: '1em',
                        margin: '.2em 0',
                        color: '#666666',
                    },
                },
            },
        },
    });
