import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css({
        textAlign: 'left',

        '.step-header': {
            marginBottom: '2em',

            '.step-header-headline': {
                fontSize: '2.3em',
                fontWeight: 600,
            },
            '.step-header-subheadline': {
                fontSize: '1.2em',
                fontWeight: 300,
            },
        },
        '.step-options': {
            fontSize: '1.1em',

            '.step-option': {
                marginBottom: '1em',
                paddingBottom: '1em',
                cursor: 'pointer',
                borderBottom: '1px solid #666666',

                '&:hover': {
                    opacity: .6,
                },

                '&.active': {
                    fontWeight: 600,
                },

                '.step-option-title': {
                    fontSize: '1.2em',
                    marginBottom: '.3em',
                },

                '.step-option-description': {
                    fontSize: '.9em',
                    color: variables.dpc_fontDark,
                },
            },
        },
    });
