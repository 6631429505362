import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class SurveyStepComponent extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                description: PropTypes.string,
            })
        ).isRequired,
    };
    static defaultProps = {
        value: null,
        subtitle: null,
    };
    
    getOption = ({ value, title, description }) => {
        const { value: currentValue, onChange } = this.props;

        return (
            <div 
                className={Classnames({
                    'step-option': true,
                    active: value === currentValue, 
                })}
                onClick={() => onChange(value)}
                key={value}
            >
                <h4 className="step-option-title">{title}</h4>
                <p className="step-option-description">{description}</p>
            </div>
        );
    }

    render() {        
        const { options, title, subtitle } = this.props;

        return (
            <StyledComponent styles={require('./styles')}>
                <div className="step-header">
                    <h2 className="step-header-headline">{title}</h2>
                    <p className="step-header-subheadline">{subtitle}</p>
                </div>
                <div className="step-options">
                    {options.map(this.getOption)}
                </div>
            </StyledComponent>
        );
    }
}
