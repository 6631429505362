import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PUBLIC_SURVEY_TRAINER } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Head from 'Components/core/Head';
import Button from 'Components/layout/Button';
import SurveyStep from 'Components/public/survey/SurveyStep';

export default class PublicSurvey extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    state = {
        currentStepIndex: 0,
        survey: [{
            name: 'goal',
            title: 'Twój cel',
            value: null,
            options: [{
                value: 'leaner',
                title: 'Być szczuplejszy',
                description: 'Chcę zrzucić kilka zbędnych kilo i poprawić pomiary ciała!',
            }, {
                value: 'body',
                title: 'Poprawić sylwetkę',
                description: 'Chcę ujędrnić sylwetkę lub zbudować muskulaturę',
            }, {
                value: 'stronger',
                title: 'Być silniejszy!',
                description: 'Chcę znacznie poprawić swoją siłę',
            }, {
                value: 'active',
                title: 'Wrócić do aktywności',
                description: 'Chcę wrócić do sportu po dłuższej przerwię!',
            }, {
                value: 'injury',
                title: 'Zredykować bóle / pozbyć się kontuzji',
                description: 'Chcę pozbyć się uciążliwych bólów, lub porozmawiać z trenerem o kontuzjach',
            }, {
                value: 'durability',
                title: 'Poprawić sprawność fizyczną',
                description: 'Chcę poprawić szybkość, wydolność, sprawność!',
            }, {
                value: 'event',
                title: 'Chcę przygotować się do wydarzenia',
                description: 'Chcę wystartować w maratonie, triathlonie lub zawodach sportowych!',
            }],
        }, {
            name: 'activityLevel',
            title: 'Twoja aktywność',
            subtitle: 'Jak często ćwiczysz w ciągu tygodnia? (Na siłowni, w domu, na zewnątrz itd.)',
            value: null,
            options: [{
                value: '0-1',
                title: '0-1 dzień w tygodniu',
            }, {
                value: '2-3',
                title: '2-3 dni w tygodniu',
            }, {
                value: '3-4',
                title: '3-4 dni w tygodniu',
            }, {
                value: '5+',
                title: 'Powyżej 5 dni w tygodniu',
            }],
        }, {
            name: 'trainingsFrequency',
            title: 'Treningi z FitAdept',
            subtitle: 'Jak często chciałbyś trenować według naszego planu?',
            value: null,
            options: [{
                value: '0-1',
                title: '0-1 dzień w tygodniu',
            }, {
                value: '2-3',
                title: '2-3 dni w tygodniu',
            }, {
                value: '3-4',
                title: '3-4 dni w tygodniu',
            }],
        }, {
            name: 'coachGender',
            title: 'Preferencje Trenera',
            value: null,
            options: [{
                value: 'woman',
                title: 'Kobieta',
            }, {
                value: 'man',
                title: 'Mężczyzna',
            }, {
                value: 'every',
                title: 'Bez znaczenia',
            }],
        }, {
            name: 'nutrition',
            title: 'Odżywianie',
            subtitle: 'Czy stosujesz jakąś dietę?',
            value: null,
            options: [{
                value: 'balanced',
                title: 'Zbilansowana',
            }, {
                value: 'vegetarian',
                title: 'Wegetariańska lub Wegańska',
            }, {
                value: 'paleo',
                title: 'Paleo, Keto',
            }, {
                value: 'help',
                title: 'Potrzebuje waszej pomocy! ',
            }, {
                value: 'other',
                title: 'Inna',
            }],
        }],
    };

    getSurveyStep = stepIndex => {
        const { survey } = this.state;
        const surveyStep = survey[stepIndex];

        return (
            <SurveyStep 
                key={surveyStep.name}
                title={surveyStep.title}
                subtitle={surveyStep.subtitle}
                onChange={value => this.onSurveyStepChange(stepIndex, value)} 
                value={surveyStep.value}
                options={surveyStep.options}
            />
        );
    }

    getSurveyResult = surveyStep => {
        const surveyOption = surveyStep.options.find(option => option.value === surveyStep.value);

        return (
            <div className="survey-step-result" key={surveyStep.name}>
                <h4 className="survey-step-title">{surveyStep.title}</h4>
                <div className="survey-step-value">{surveyOption && surveyOption.title || 'Brak'}</div>
            </div>
        );
    }

    onSurveyStepChange = (stepIndex, value) => {
        this.setState(prevState => ({
            survey: prevState.survey.map(
                (surveyStep, index) => index === stepIndex 
                    ? ({ ...surveyStep, value })
                    : surveyStep
            ),
        }));
    }

    goToPrevStep = () => this.setState(prevState => ({ currentStepIndex: prevState.currentStepIndex - 1 }));
    goToNextStep = () => {
        const { history } = this.props;

        this.setState(prevState => {
            //Check if user is on last step
            if(prevState.currentStepIndex === prevState.survey.length - 1) {
                //If yes refirect to next page
                history.push(PUBLIC_SURVEY_TRAINER.path);
                return {};
            } else {
                //If no increment step
                return { currentStepIndex: prevState.currentStepIndex + 1 };
            }
        });
    };

    render() {
        const { survey, currentStepIndex } = this.state;

        return (
            <StyledComponent
                className="component-survey-page" 
                styles={require('./styles')}
            >
                <Head
                    title="meta.title"
                    description="meta.description"
                />
                <LayoutContainer>
                    <div className="survey-step-question">
                        {this.getSurveyStep(currentStepIndex)}
                        <div className="survey-step-buttons">
                            {currentStepIndex > 0 &&
                                <Button 
                                    onClick={this.goToPrevStep}
                                >
                                    Wstecz
                                </Button>
                            }
                            <Button 
                                onClick={this.goToNextStep} 
                                disabled={!survey[currentStepIndex].value}
                            >
                                Dalej
                            </Button>
                        </div>
                        
                    </div>
                    <div className="survey-steps-results">
                        {survey.map(this.getSurveyResult)}
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
